import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LegalRoutingModule } from './legal-routing.module';
import { ImprintPageComponent } from "./pages/imprint-page/imprint-page.component";
import { PrivacyPolicyPageComponent } from "./pages/privacy-policy-page/privacy-policy-page.component";
import { TermsOfServicePageComponent } from "./pages/terms-of-service-page/terms-of-service-page.component";

@NgModule({
  declarations: [
    ImprintPageComponent,
    PrivacyPolicyPageComponent,
    TermsOfServicePageComponent,
  ],
  imports: [
    CommonModule,
    LegalRoutingModule
  ]
})
export class LegalModule { }
