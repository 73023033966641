import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyPolicyPageComponent } from "./pages/privacy-policy-page/privacy-policy-page.component";
import { TermsOfServicePageComponent } from "./pages/terms-of-service-page/terms-of-service-page.component";
import { ImprintPageComponent } from "./pages/imprint-page/imprint-page.component";

const routes: Routes = [
    {
      path: 'privacy-policy', component: PrivacyPolicyPageComponent,
      data: {
        title: $localize `Privacy Policy`,
        // description: $localize ``,
        // ogUrl: 'https://www.example.com/players'
      }
    },
    {
      path: 'terms-of-service', component: TermsOfServicePageComponent,
      data: {
        title: $localize `Terms of Service`,
        // description: $localize ``,
        // ogUrl: 'https://www.example.com/players'
      }
    },
    {
      path: 'imprint', component: ImprintPageComponent,
      data: {
        title: $localize `Imprint`,
        // description: $localize ``,
        // ogUrl: 'https://www.example.com/players'
      }
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LegalRoutingModule { }
