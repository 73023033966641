<div>
  <h1 class="mb-6" i18n="@@title">Imprint</h1>

  <div class="mb-5">
    <h3 i18n="@@contact">Contact</h3>
    <p>Telefon: 01743253450</p>
    <p>E-Mail: contact&#64;circlesquare.app</p>
  </div>

  <div class="mb-5">
    <h3 i18n="@@sales-taxt">Sales tax ID</h3>
    <p i18n>Umsatzsteuer-Identifikationsnummer according to § 27 a Umsatzsteuergesetz: <br> DE359601271</p>
  </div>

  <div class="mb-5">
    <h3 i18n="@@dispute.title">Dispute Resolution</h3>
    <p i18n="@@dispute.line1">The European Commission provides a platform for online dispute resolution (OS): https://ec.europa.eu/consumers/odr/.</p>
    <p i18n="@@dispute.line2">Our email address can be found at the top of the imprint.</p>
    <p i18n="@@dispute.line3">Consumer Dispute Resolution/Universal Dispute Resolution Body</p>
    <p i18n="@@dispute.line4">We are not willing or obliged to participate in dispute resolution proceedings before a consumer dispute resolution body.</p>
  </div>

  <div class="mb-5">
    <h3 i18n="@@5tmg">Information according to § 5 TMG</h3>
    <p>KreisViereck UG (haftungsbeschränkt)</p>
    <p>c/o CODE Education GmbH, Donaustraße 44, 12043 Berlin</p>
    <p>Handelsregister: HRB 247204 B</p>
    <p>Registergericht: Amtsgericht Charlottenburg (Berlin)</p>
  </div>

  <div class="mb-5">
    <h3 i18n="@@represented">Represented by</h3>
    <p>Pablo-André Schleßelmann</p>
    <p>Lion Alexander Daniel Reinacher</p>
    <p>Max Holz</p>
  </div>
</div>
