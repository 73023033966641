<div>
  <main>

    <h1 class="mb-6">Privacy Policy</h1>
    <p>This Application collects some Personal Data from its Users.</p>
    <h3 class="mb-5 mt-5">Personal Data processed for the following purposes and using the following services:</h3>

    <table border="1" cellpadding="5" cellspacing="0">
      <thead>
      <tr>
        <th>Purpose</th>
        <th>Service</th>
        <th>Personal Data Collected</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>Beta-Testing</td>
        <td>TestFlight, Google Play Beta Testing</td>
        <td>App information, country, Data communicated while using the service, date of birth, device information, email address, first name, gender, last name, phone number, Trackers, Usage Data, username</td>
      </tr>
      <tr>
        <td>=Tracking and Session recording</td>
        <td>Mixpanel Tracking</td>
        <td>Trackers, Usage Data, various types of Data as specified in the privacy policy of the service</td>
      </tr>
      <tr>
        <td>Device permissions for Personal Data access</td>
        <td>Device permissions for Personal Data access</td>
        <td>Calendar permission, Camera permission, Photo Library permission, Precise location permission (continuous), Precise location permission (non-continuous), Reminders permission</td>
      </tr>
      <tr>
        <td>Hosting and backend infrastructure</td>
        <td>Amazon Web Services (AWS), Firebase Cloud Functions</td>
        <td>Various types of Data as specified in the privacy policy of the service, Usage Data</td>
      </tr>
      <tr>
        <td>Contacting the User</td>
        <td>Contact form, Mailing list or newsletter</td>
        <td>Email address, first name, User ID</td>
      </tr>
      <tr>
        <td>Social features</td>
        <td>Inviting and suggesting friends</td>
        <td>Various types of Data</td>
      </tr>
      <tr>
        <td>Location-based interactions</td>
        <td>Geolocation and Non-continuous geolocation</td>
        <td>Geographic position</td>
      </tr>
      <tr>
        <td>Managing contacts and sending messages</td>
        <td>Amazon Simple Email Service (SES)</td>
        <td>Email address</td>
      </tr>
      <tr>
        <td>Registration and authentication provided directly by this Application</td>
        <td>Direct registration</td>
        <td>Date of birth, email address, first name, language, last name, prefix, profile picture, User ID, username</td>
      </tr>
      <tr>
        <td>SPAM protection</td>
        <td>Google reCAPTCHA</td>
        <td>Answers to questions, clicks, keypress events, motion sensor events, mouse movements, scroll position, touch events, Trackers, Usage Data</td>
      </tr>
      <tr>
        <td>Content performance and features testing (A/B testing)</td>
        <td>Firebase Remote Config</td>
        <td>Various types of Data as specified in the privacy policy of the service</td>
      </tr>
      <tr>
        <td>Platform services and hosting</td>
        <td>Webflow</td>
        <td>Usage data</td>
      </tr>
      <tr>
        <td>Payment services</td>
        <td>Stripe and PayPal</td>
        <td>Name, E-Mail address, IP address. Data required for the payment process: Credit Card number, expiration date, security code. Stripe and PayPal may hand data to third party contractors if necessary in order to fulfill their obligations. Consent for the processing of personal data may be revoked at any time.</td>
      </tr>
      </tbody>
    </table>
    <!--
        <h3 class="mt-1">Beta-Testing</h3>
        <p>
            TestFlight<br>
            Personal Data: app information; country; Data communicated while using the service; date of birth; device information; email address; first name; gender; last name; phone number; Trackers; Usage Data; username
        </p>
        <h3 class="mt-1">Google Play Beta Testing</h3>
        <p>
            Personal Data: country; Data communicated while using the service; date of birth; device information; device logs; email address; first name; gender; last name; phone number; Trackers; Usage Data; username
        </p>
        <h3 class="mt-1">Heat mapping and session recording</h3>
        <p>
            Hotjar Heat Maps &amp; Recordings<br>
            Personal Data: Trackers; Usage Data; various types of Data as specified in the privacy policy of the service
        </p>
        <h3 class="mt-1">Device permissions for Personal Data access</h3>
        <p>
            Device permissions for Personal Data access<br>
            Personal Data: Calendar permission; Camera permission; Photo Library permission; Precise location permission (continuous); Precise location permission (non-continuous); Reminders permission
        </p>
        <h3 class="mt-1">Hosting and backend infrastructure</h3>
        <p>
            Amazon Web Services (AWS)<br>
            Personal Data: various types of Data as specified in the privacy policy of the service
        </p>
        <h3 class="mt-1">Firebase Cloud Functions</h3>
        <p>
            Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service
        </p>
        <h3 class="mt-1">Contacting the User</h3>
        <p>
            Contact form<br>
            Personal Data: email address; first name; User ID
        </p>
        <p>
            Mailing list or newsletter<br>
            Personal Data: email address
        </p>
        <h3 class="mt-1">Social features</h3>
        <p>
            Inviting and suggesting friends<br>
            Personal Data: various types of Data
        </p>
        <h3 class="mt-1">Location-based interactions</h3>
        <p>
            Geolocation and Non-continuous geolocation<br>
            Personal Data: geographic position
        </p>
        <h3 class="mt-1">Managing contacts and sending messages</h3>
        <p>
            Amazon Simple Email Service (SES)<br>
            Personal Data: email address
        </p>
        <h3 class="mt-1">Registration and authentication provided directly by this Application</h3>
        <p>
            Direct registration<br>
            Personal Data: date of birth; email address; first name; language; last name; prefix ; profile picture; User ID; username
        </p>
        <h3 class="mt-1">SPAM protection</h3>
        <p>
            Google reCAPTCHA<br>
            Personal Data: answers to questions; clicks; keypress events; motion sensor events; mouse movements; scroll position; touch events; Trackers; Usage Data
        </p>
        <h3 class="mt-1">Content performance and features testing (A/B testing)</h3>
        <p>
            Firebase Remote Config<br>
            Personal Data: various types of Data as specified in the privacy policy of
            service
        </p>
        <h3 class="mt-1">Further information about the processing of Personal Data</h3>
        <p>
            <h3 class="mt-1">Push notifications</h3>
            This Application may send push notifications to the User to achieve the purposes outlined in this privacy policy. Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then change those settings for this Application, some or all of the apps on the particular device.
            Users must be aware that disabling push notifications may negatively affect the utility of this Application.
        </p>
        <p>
            <h3 class="mt-1">Push notifications based on the User's geographic location</h3>
            This Application may use the User's geographic location to send push notifications for the purposes outlined in this privacy policy. Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then changing those settings for some or all of the apps on the particular device. Users must be aware that disabling push notifications may negatively affect the utility of this Application.
        </p>
        <p>
            <h3 class="mt-1">Push notifications for direct marketing</h3>
            This Application may send push notifications to the User for the purpose of direct marketing (to propose services and products provided by third parties or unrelated to the product or service provided by this Application). Users may in most cases opt-out of receiving push notifications by visiting their device settings, such as the notification settings for mobile phones, and then changing those settings for this Application or all of the apps on the particular device. Users must be aware that disabling push notifications may negatively affect the utility of this Application. Besides applicable device settings, the User may also make use of the rights described under User rights in the relevant section of this privacy policy.
        </p>
        <p>
            <h3 class="mt-1">User identification via a universally unique identifier (UUID)</h3>
            This Application may track Users by storing a so-called universally unique identifier (or short UUID) for analytics purposes or for storing Users' preferences. This identifier is generated upon installation of this Application, it persists between Application launches and updates, but it is lost when the User deletes the Application. A reinstall generates a new UUID.
        </p>
        <p>
            <h3 class="mt-1">Automated decision-making</h3>
            Automated decision-making means that a decision which is likely to have legal effects or similarly significant effects on the User, is taken solely by technological means, without any human intervention. This Application may use the User's Personal Data to make decisions entirely or partially based on automated processes according to the purposes outlined in this document. This Application adopts automated decision-making processes as far as necessary to enter into or perform a contract between User and Owner, or on the basis of the User’s explicit consent, where such consent is required by the law.
            Automated decisions are made by technological means – mostly based on algorithms subject to predefined criteria – which may also be provided by third parties.
            The rationale behind the automated decision making is: to enable or otherwise improve the decision-making process; to grant Users fair and unbiased treatment based on consistent and uniform criteria; to reduce the potential harm derived from human error, personal bias and the like which may potentially lead to discrimination or imbalance in the treatment of individuals etc.; to reduce the risk of User's failure to meet their obligation under a contract. To find out more about the purposes, the third-party services, if any, and any specific rationale for automated decisions used within this Application, Users can check the relevant sections in this document.
        </p>

        <p>
            <h3 class="mt-1">Consequences of automated decision-making processes for Users and rights of Users subjected to it</h3>
            As a consequence, Users subject to such processing, are entitled to exercise specific rights aimed at preventing or otherwise limiting the potential effects of the automated decisions taken.
            In particular, Users have the right to: obtain an explanation about any decision taken as a result of automated decision-making and express their point of view regarding this decision; challenge a decision by asking the Owner to reconsider it or take a new decision on a different basis; request and obtain from the Owner human intervention on such processing. To learn more about the User’s rights and the means to exercise them, the User is invited to consult the section of this document relating to the rights of the User.
        </p>
        <p>
            <h3 class="mt-1">Analysis and predictions based on the User’s Data (“profiling”)</h3>
            The Owner may use the Personal and Usage Data collected through this Application to create or update User profiles. This type of Data processing allows the Owner to evaluate User choices, preferences and behaviour for the purposes outlined in the respective section of this document.
            User profiles can also be created through the use of automated tools like algorithms, which can also be provided by third parties. To find out more about the profiling activities performed, Users can check the relevant sections of this document.
            The User always has a right to object to this kind of profiling activity. To find out more about the User's rights and how to exercise them, the User is invited to consult the section of this document outlining the rights of the User.
        </p>
        <p>
            <h3 class="mt-1">Browser Fingerprinting</h3>
            Browser Fingerprinting creates an identifier based on a device's unique combination of characteristics (e.g. IP address, HTTP header, browser properties etc.), that allows to distinguish the User from other Users, thereby enabling to track the User's browsing behavior across the web. Browser Fingerprinting does not have an expiration date and cannot be deleted.
        </p>
        <p>
            <h3 class="mt-1">localStorage</h3>
            localStorage allows this Application to store and access data right in the User's browser with no expiration date.
        </p>
        <p>
            <h3 class="mt-1">Unique device identification</h3>
            This Application may track Users by storing a unique identifier of their device, for analytics purposes or for storing Users' preferences.
        </p>
        <p>
            <h3 class="mt-1">Access the address book</h3>
            This Application may request access to your address book.
        </p>
        <p>
            <h3 class="mt-1">Platform services and hosting</h3>
            Webflow
            Personal data: Usage data
        </p> -->
    <p>
    <h3 class="mt-5">Contact information</h3>
    Owner and Data Controller
    KreisViereck UG Haftungsbeschränkt c/o Factory Works GmbH
    Lohmühlenstraße 65
    12435 Berlin
    Owner contact email: contact&#64;circlesquare.app
  </main>

</div>
